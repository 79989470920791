import React from "react";

function ChevronTab(props) {
  return (
    <svg
      id="arrow_xl"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect id="placeholder" width="24" height="24" fill="#fff" opacity="0" />
      <path
        id="Path_10495"
        data-name="Path 10495"
        d="M-15190-9317l8.759,7.006,8.757-7.006"
        transform="translate(15193.242 9325.495)"
        fill="none"
        stroke="#1f2c4d"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
    </svg>
  );
}

export default ChevronTab;
