import React from "react";

function ChevronTabMobile(props) {
  return (
    <svg
      id="arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g id="arrow-2" data-name="arrow" transform="translate(-688.02 -5440.02)">
        <rect
          id="placeholder"
          width="14"
          height="14"
          transform="translate(688.02 5440.02)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_10493"
          data-name="Path 10493"
          d="M-15190-9317l5,4,5-4"
          transform="translate(15880.02 14762.02)"
          fill="none"
          stroke="#1f2c4d"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export default ChevronTabMobile;
