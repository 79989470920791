import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Legals from "../components/Legals";

const LegalsPages = () => {
  return (
    <div className="legals-page">
      <Header />
      <Legals />
      <Footer />
    </div>
  );
};

export default LegalsPages;
