import React from "react";
import Arrow from "../assets/illustration/arrow-back.svg";

const Legals = () => {
  return (
    <div className="Legals">
      <a href="/" className="retour">
        <img src={Arrow} alt="Arrow retour" className="arrowBack" />
        <p>Retourner à l'acceuil</p>
      </a>
      <div className="textLegals">
        <h1>Mentions légales</h1>
        <h3>Responsable de publication </h3>
        <p>Monsieur Bruno BERCHIATTI, Président de PRESTAE</p>
        <h3>Informations légales </h3>{" "}
        <p>
          PRESTAE, Société à responsabilité limitée au capital social de 1
          000,00 €, dont le siège social est situé au 50 IMPASSE DE LA PLACETTE,
          74800 ETAUX, immatriculée au Registre du Commerce et des Sociétés
          d’Annecy sous le numéro 908 942 709 (TVA intracom n°FR29908942709).
          Code APE 66.30Z.
        </p>{" "}
        <h3>Contact </h3>{" "}
        <p>
          Par téléphone : +33 7 88 80 55 04 <br /> Par mail :
          bruno.berchiatti@prestae-funds.com <br />
          Par courrier : 50 imp de la placette, 74800 Etaux, France
        </p>{" "}
        <h3>Hébergeur </h3>{" "}
        <p>
          OVH SAS au capital de 10 174 560 € <br /> RCS Lille Métropole 424 761
          419 00045 <br />
          Code APE 2620Z
          <br />
          Siège social : 2 rue Kellermann - 59100 Roubaix - France
        </p>
        <div className="paraLegals">
          <h3>À savoir </h3>
          PRESTAE vous propose de mettre à sa disposition vos actifs numériques
          dans le cadre d’un prêt de consommation au sens de l’article 1892 du
          Code civil, afin d’augmenter sa capacité d’investissement sur des
          applications de finance décentralisée. Le prêt entraîne la
          dépossession de vos actifs numériques avec restitution par équivalent
          et versement d’un intérêt selon les modalités détaillées dans le
          contrat de prêt. Il ne s’agit ni d’un crédit au sens de l’article
          L.313-1 du Code monétaire et financier, ni d’un des services pour
          compte de tiers prévus à l’article L.54-10-2 du Code monétaire et
          financier. Nous vous rappelons que les données disponibles sur ce site
          et concernant les actifs numériques sont fournies à titre indicatif
          uniquement, et que les performances passées ne préjugent pas des
          performances futures. La technologie sous-jacente à l’émission et la
          circulation des actifs numériques (Dispositif d’Enregistrement
          Électronique Partagé, ou « DEEP» ou « Blockchain ») doit être
          considérée comme expérimentale et comporte en conséquence des risques
          techniques importants tels que des erreurs de code, des
          dysfonctionnements et des risques de sécurité imprévisibles et
          irrésistibles tel que le piratage informatique de comptes d’actifs
          numériques, ainsi que des risques financiers tels que la perte
          partielle ou totale du capital.
        </div>
        <div className="condiLegals">
          Nos{" "}
          <a
            href="./Media/Conditions.pdf"
            target="_blank"
            className="legalsLiens"
          >
            conditions générales d'utilisation
          </a>
        </div>
        <div className="poliLegals">
          Notre{" "}
          <a
            href="./Media/Politique.pdf"
            target="_blanck"
            className="legalsLiens"
          >
            politique de confidentialité
          </a>
        </div>
      </div>
    </div>
  );
};

export default Legals;
