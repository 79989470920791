import React from "react";
import contratImg from "../assets/illustration/contrat_icon.svg";
import transparenceImg from "../assets/illustration/transparence_icon.svg";
import guarantieImg from "../assets/illustration/guarantie_icon.svg";
import stableImg from "../assets/illustration/stablecoin_icon.svg";
import Illu1 from "../assets/illustration/illustration-1_light.svg";
import equite from "../assets/illustration/equite_icon.svg";
import rapide from "../assets/illustration/rapide_icon.svg";
import securite from "../assets/illustration/Securite_icon.svg";
import Illu2 from "../assets/illustration/illustration-2.svg";
import LogoP from "../assets/Logo/icon_mark_color.svg";
import { HashLink as Link } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import Tableau from "./Tableau";
import TableauMobile from "./TableauMobile";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 720 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return isMobile ? children : null;
};

const FirstPage = () => {
  return (
    <div className="form-component">
      <div className="gradiantForm"></div>
      <div className="grandiantForm2"></div>
      <div className="Presentation">
        <h1>
          Ensemble,
          <br />
          <mark className="titleBlue">Nous Construisons</mark>
          <br />
          Le Futur de l'épargne
        </h1>
        <img src={Illu1} className="Illu1" alt="illustration" />
        <p>
          Prêtez vos crypto-monnaies en
          <br />
          <span className="overlign2">toute sécurité </span>et en
          <span className="overlign2"> toute sérénité</span>
        </p>

        <Link className="btn-sort" id="contact" to="#idcontact">
          Contactez-nous !
        </Link>
      </div>

      <div className="Descriptif">
        <img src={Illu2} className="Illu2" alt="Logo-finance" />
        <div className="grandiantForm3"></div>
        <h1>
          La finance décentralisée (DeFi),{" "}
          <mark className="titleBlue">une révolution économique.</mark>
        </h1>
        <p>
          Notre activité est une{" "}
          <strong>activité de gestion de fonds propres.</strong> Pour réaliser
          cela, nous utilisons les <strong>crypto-monnaies</strong> et
          l'écosystème de la <strong>finance décentralisée</strong>.
          <br />
          <br /> Nous sommes conscients de la difficulté et de la complexité que
          peut représenter la finance et l’univers des crypto-monnaies.{" "}
          <strong>
            C’est pourquoi, nous vous donnons la possibilité de nous prêter vos
            crypto en échange d’un intérêt annuel.
          </strong>
          <br />
          <br />{" "}
          <strong>
            La DeFi est similaire à la finance traditionnelle
          </strong>{" "}
          mais elle permet de se passer des tiers de confiance (banques,
          assurances etc.) car elle est{" "}
          <strong>régie par des algorithmes et non par des humains.</strong> Il
          s’agit, pour faire simple, de la <strong>finance P2P</strong> (pair à
          pair).
        </p>
      </div>

      <div className="Avantages">
        <h1>
          Les avantages de la <span className="overlign">DeFi</span>
        </h1>
        <div className="containerAV">
          <div className="grandiantForm4"></div>
          <div className="Av-1">
            <img src={rapide} className="illu-av" alt="logo" />
            <div className="box">
              <h3>Rapidité</h3>
              <p>
                Chaque transaction est réalisée en moins d’une minute, ce qui
                nous permet d’adapter et d’optimiser rapidement nos
                investissements.
              </p>
            </div>
          </div>
          <div className="Av-2">
            <img src={securite} className="illu-av" alt="logo" />
            <div className="box">
              <h3>Sécurité</h3>
              <p>
                Enlever des tiers humains, comme les banques ou les assurances,
                permet de réduire les erreurs humaines.
              </p>
            </div>
          </div>
          <div className="Av-3">
            <img src={equite} className="illu-av" alt="logo" />
            <div className="box">
              <h3>Equité</h3>
              <p>
                Peu importe votre capital, vous aurez accès aux mêmes services
                ainsi qu’aux mêmes contreparties.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="societe" id="APropos">
        <img src={LogoP} className="LogoP" alt="logoPrestae" />
        <h1>
          <span className="overlign">Prestae</span>, <br />
          qu'est-ce que c'est ?
        </h1>
        <p>
          Prestae est une société <strong>spécialisée</strong> dans
          l’investissement de ses <strong>fonds propres</strong> sur des
          plateformes de <strong>finance décentralisée</strong>, qui a pour
          objectif de répondre à la problématique majeure de l’accès difficile à
          la DeFi.
          <br />
          <br />
          C'est pourquoi nous avons mis en place une <strong>
            solution
          </strong>{" "}
          vous permettant de faire fructifier vos{" "}
          <strong>actifs numériques</strong> tout en{" "}
          <strong>réduisant les risques</strong> habituellement associés à
          l'investissement en crypto-monnaies (incompréhension de la DeFi,
          mauvaises décisions…).
        </p>
      </div>
      <Desktop>
        <Tableau />
      </Desktop>
      <Mobile>
        <TableauMobile />
      </Mobile>
      <div className="Explication">
        <h1>
          Pourquoi nous faire <span className="overlign5">confiance</span>
        </h1>
        <div className="containerEX">
          <div className="Raison-1">
            <img src={stableImg} className="Picto" alt="Exemple" />
            <h3>Investissements en crypto-monnaies</h3>

            <p>
              Tous les investissements que nous faisons sont faits avec des
              <strong> crypto-monnaies</strong>. En effet, nous empruntons vos
              crypto-monnaies pour les faire <strong>travailler</strong> dans
              l’écosystème de la
              <strong> DeFi</strong>. Si vous avez prévu de garder vos
              crypto-monnaies sur du
              <strong> moyen/long terme</strong> et que vous voulez en avoir de{" "}
              <strong>plus en plus</strong>, alors vous êtes au{" "}
              <strong>bon endroit</strong> !
            </p>
            <a href="#FAQ">Comment acheter des crypto-monnaies ?</a>
          </div>
          <div className="Raison-2">
            <img src={guarantieImg} className="Picto" alt="Exemple" />
            <h3>Capital & intérêts 100% garanti</h3>

            <p>
              Le montant du capital prêté est en{" "}
              <strong>intégralité restitué</strong> en même
              <strong> qualité</strong> et <strong>quantité</strong> que l'objet
              du contrat, aux termes de ce dernier. Les intérêts sont versés
              <strong> périodiquement</strong> et eux aussi{" "}
              <strong>garantis </strong>
              (voir tableau{" "}
              <a href="#tableauid" className="lienstab">
                ici
              </a>
              ). Les intérêts sont distribués avec <strong>la même</strong>{" "}
              crypto-monnaie que vous nous avez prêté (objet du contrat)
            </p>
            <a href="#FAQ">Comment les intérêts sont-ils garantis ?</a>
          </div>
          <div className="Raison-3">
            <img src={contratImg} className="Picto" alt="Exemple" />
            <h3>Contrats rédigés par HALT AVOCATS</h3>

            <p>
              Les conventions de prêts que nous proposons ont été{" "}
              <strong>intégralement </strong>
              rédigées par un cabinet d’avocats{" "}
              <strong>spécialisés dans la technologie blockchain </strong>{" "}
              <a
                href="https://halt-avocats.fr/"
                className="halt"
                target="_blank"
                rel="noreferrer"
              >
                HALT AVOCATS
              </a>{" "}
              afin de <strong>protéger </strong>
              au mieux notre activité et <strong>
                toutes les personnes
              </strong>{" "}
              qui voudraient ouvrir un contrat chez nous. Nos contrats sont des
              prêts de consommation définis à l’article 1892 du Code civil.
            </p>
            <div className="ancre">
              <a href="#FAQ">Quelle est la durée du contrat ? </a>
              <a href="#FAQ">Comment ouvrir un contrat ?</a>
            </div>
          </div>
          <div className="Raison-4">
            <img src={transparenceImg} className="Picto" alt="Exemple" />
            <h3>Transparence et réactivité</h3>
            <p>
              Nous nous engageons à un maximum de <strong>transparence</strong>{" "}
              quant à notre activité, c’est pourquoi, si vous le souhaitez, vous
              pouvez suivre tous nos investissements et toutes nos actions sur
              la <strong>blockchain</strong>. Nous nous efforçons de répondre le
              plus <strong>rapidement</strong> à vos demandes, afin de{" "}
              <strong>satisfaire</strong> au mieux vos attentes.
            </p>
            <div className="ancre2">
              <a href="#FAQ">Comment sont déterminés les intérêts ?</a>
              <a href="#FAQ">Y a-t-il des frais ?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
