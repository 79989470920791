import React from "react";
import FirstPage from "../components/FirstPage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FormContact from "../components/FormContact";
import NextStep from "../components/NextStep";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "../components/Accordion";

const Home = () => {
  return (
    <div className="home-page">
      <Header />
      <FirstPage />
      <NextStep />
      <FormContact />
      <Accordion />
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Home;
