import React from "react";
import ImgDesktop from "../assets/illustration/image-2_desktop.svg";
import ImgMobile from "../assets/illustration/image-2_mobile.svg";
import ImgTablette from "../assets/illustration/image-2_tablet.svg";

const NextStep = () => {
  return (
    <div className="nextstep">
      <h1>
        <span className="overlign4">Prochainement :</span>{" "}
        <span className="overlign">un espace utilisateur automatisé !</span>
      </h1>
      <div className="grandiantForm5"></div>
      <img src={ImgDesktop} alt="ImageDestok" className="Illu-pro" />
      <img src={ImgMobile} alt="ImageMobile" className="Illu-Mobile" />
      <img src={ImgTablette} alt="ImageTablet" className="Illu-Tablet" />
      <div className="container2">
        <h2>
          Et <span className="overlign3">plus encore </span>
          dans les <span className="overlign3">prochaines versions</span> de
          notre site !
        </h2>
        <p>
          N'hésitez pas à{" "}
          <strong className="nextstrong">revenir régulièrement</strong> sur
          notre site internet pour
          <strong className="nextstrong"> rester au courant</strong> de notre
          activité.
          <br /> Dans les prochaines versions du site, vous aurez accès à un{" "}
          <strong className="nextstrong">
            simulateur de nos contrats
          </strong>, <br />
          un espace utilisateur{" "}
          <strong className="nextstrong">entièrement personnel</strong> et bien
          plus !
        </p>
      </div>
      <h4 id="idcontact">Contactez-nous !</h4>
    </div>
  );
};

export default NextStep;
