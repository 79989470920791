import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { HashLink as Link } from "react-router-hash-link";
import ChevronTabMobile from "./Svg/ChevtonTabMobile";

import "../styles/components/_tableauMobile.scss";

export default function Tableau() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="NeedAccM" id="tableauid">
      <div className="CryptotextM">
        <h2>
          Nos <span className="blueCrypto">actifs</span> acceptés
        </h2>
        <p>
          Tous les actifs que nous empruntons et que nous faisons travailler à
          votre place !
        </p>
      </div>
      <div className="tabAccM">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary id="panel1a-header">
            <Typography className="BTCM">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="47.995"
                height="48"
                viewBox="0 0 47.995 48"
              >
                <g id="bitcoin-btc-logo" transform="translate(0 -0.001)">
                  <g id="_1421344023328" transform="translate(0 0.001)">
                    <path
                      id="Path_11121"
                      data-name="Path 11121"
                      d="M47.276,29.806A23.992,23.992,0,1,1,29.8.719,23.994,23.994,0,0,1,47.276,29.806h0Z"
                      transform="translate(0 -0.001)"
                      fill="#f7931a"
                    />
                    <path
                      id="Path_11122"
                      data-name="Path 11122"
                      d="M1018.372,724.969c.478-3.194-1.954-4.911-5.279-6.056l1.079-4.326-2.634-.656-1.05,4.212c-.692-.173-1.4-.335-2.11-.5l1.058-4.24-2.632-.656-1.079,4.325c-.573-.13-1.136-.259-1.682-.4l0-.014-3.632-.907-.7,2.813s1.954.448,1.913.475a1.4,1.4,0,0,1,1.227,1.532l-1.229,4.929a2.144,2.144,0,0,1,.274.088l-.278-.069-1.722,6.9a.958.958,0,0,1-1.207.626c.026.038-1.914-.478-1.914-.478l-1.307,3.015,3.427.854c.638.16,1.262.327,1.878.484l-1.09,4.376,2.631.656,1.079-4.33c.719.2,1.416.375,2.1.545l-1.076,4.309,2.634.656,1.09-4.368c4.491.85,7.868.507,9.289-3.555,1.145-3.27-.057-5.157-2.42-6.387a4.2,4.2,0,0,0,3.363-3.867h0Zm-6.017,8.438c-.814,3.27-6.32,1.5-8.105,1.059l1.446-5.8c1.785.446,7.51,1.328,6.659,4.738Zm.815-8.485c-.742,2.975-5.325,1.463-6.812,1.093l1.311-5.258c1.487.371,6.274,1.062,5.5,4.165Z"
                      transform="translate(-983.792 -704.389)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              <div className="nomCryptoM">
                <span className="NomCompletM">Bitcoin</span>
                <span className="nomAbreM">BTC</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">1,25%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Non</span>
            </Typography>
            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">6 périodes de 6 mois</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">0.01</span>
            </Typography>

            <Typography className="FraisSecondM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">0.001</span>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary id="panel2a-header">
            <Typography className="ETHM">
              <svg
                id="ethereum-eth-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="29.474"
                height="48"
                viewBox="0 0 29.474 48"
              >
                <g id="_1421394342400">
                  <g id="Group_11255" data-name="Group 11255">
                    <path
                      id="Path_11128"
                      data-name="Path 11128"
                      d="M383.822,0,383.5,1.094V32.832l.322.321,14.732-8.708Z"
                      transform="translate(-369.089)"
                      fill="#343434"
                    />
                    <path
                      id="Path_11129"
                      data-name="Path 11129"
                      d="M14.733,0,0,24.445l14.733,8.708V0Z"
                      fill="#8c8c8c"
                    />
                    <path
                      id="Path_11130"
                      data-name="Path 11130"
                      d="M387.422,733.594l-.182.221v11.306l.182.53,14.741-20.761Z"
                      transform="translate(-372.689 -697.651)"
                      fill="#3c3c3b"
                    />
                    <path
                      id="Path_11131"
                      data-name="Path 11131"
                      d="M14.733,745.651V733.594L0,724.89Z"
                      transform="translate(0 -697.651)"
                      fill="#8c8c8c"
                    />
                    <path
                      id="Path_11132"
                      data-name="Path 11132"
                      d="M392.07,487.735l14.732-8.708-14.732-6.7Z"
                      transform="translate(-377.337 -454.581)"
                      fill="#141414"
                    />
                    <path
                      id="Path_11133"
                      data-name="Path 11133"
                      d="M0,479.027l14.733,8.708V472.33Z"
                      transform="translate(0 -454.581)"
                      fill="#393939"
                    />
                  </g>
                </g>
              </svg>
              <div className="nomCryptoETHM">
                <span className="NomCompletM">Ethereum</span>
                <span className="nomAbreM">ETH</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">3,50%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Non</span>
            </Typography>

            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">6 périodes de 6 mois</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">0.1</span>
            </Typography>
            <Typography className="FraisSecondM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">0.01</span>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary id="panel3a-header">
            <Typography className="TetherM">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="41.756"
                viewBox="0 0 48 41.756"
              >
                <g id="tether-usdt-logo" transform="translate(-0.005 0)">
                  <path
                    id="Path_11143"
                    data-name="Path 11143"
                    d="M8.794.2.041,18.589a.356.356,0,0,0,.076.416L23.756,41.656a.361.361,0,0,0,.5,0l23.638-22.65a.356.356,0,0,0,.076-.416L39.217.206A.354.354,0,0,0,38.895,0H9.119A.354.354,0,0,0,8.794.2Z"
                    transform="translate(0)"
                    fill="#50af95"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_11144"
                    data-name="Path 11144"
                    d="M82.081,55.018h0c-.17.013-1.046.065-3,.065-1.556,0-2.66-.047-3.048-.065h0c-6.012-.264-10.5-1.311-10.5-2.564s4.487-2.3,10.5-2.567v4.088c.393.028,1.519.095,3.074.095,1.867,0,2.8-.078,2.97-.093V49.89c6,.267,10.476,1.314,10.476,2.564s-4.476,2.3-10.476,2.562h0Zm0-5.551V45.809h8.372V40.23H67.66v5.579H76.03v3.657c-6.8.313-11.92,1.66-11.92,3.275s5.117,2.961,11.92,3.275V67.74h6.05V56.012c6.788-.313,11.9-1.659,11.9-3.272s-5.1-2.96-11.9-3.274h0Zm0,0Z"
                    transform="translate(-55.039 -34.541)"
                    fill="#fff"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div className="nomCryptoM">
                <span className="NomCompletM">Tether USD</span>
                <span className="nomAbreM">USDT</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">5,50%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Oui</span>
            </Typography>

            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">5 périodes de 1 an</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">300</span>
            </Typography>
            <Typography className="FraisM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">20</span>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary id="panel4a-header">
            <Typography className="USDCoinM">
              <svg
                id="usd-coin-usdc-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  id="Path_11145"
                  data-name="Path 11145"
                  d="M24,48A24,24,0,1,0,0,24,23.942,23.942,0,0,0,24,48Z"
                  fill="#2775ca"
                />
                <path
                  id="Path_11146"
                  data-name="Path 11146"
                  d="M730.07,523.93c0-3.5-2.1-4.7-6.3-5.2-3-.4-3.6-1.2-3.6-2.6s1-2.3,3-2.3c1.8,0,2.8.6,3.3,2.1a.752.752,0,0,0,.7.5h1.6a.683.683,0,0,0,.7-.7v-.1a5,5,0,0,0-4.5-4.1v-2.4c0-.4-.3-.7-.8-.8h-1.5c-.4,0-.7.3-.8.8v2.3c-3,.4-4.9,2.4-4.9,4.9,0,3.3,2,4.6,6.2,5.1,2.8.5,3.7,1.1,3.7,2.7s-1.4,2.7-3.3,2.7c-2.6,0-3.5-1.1-3.8-2.6a.73.73,0,0,0-.7-.6h-1.7a.683.683,0,0,0-.7.7v.1c.4,2.5,2,4.3,5.3,4.8v2.4c0,.4.3.7.8.8h1.5c.4,0,.7-.3.8-.8v-2.4c3-.5,5-2.6,5-5.3Z"
                  transform="translate(-699.47 -496.13)"
                  fill="#fff"
                />
                <path
                  id="Path_11147"
                  data-name="Path 11147"
                  d="M260.914,322.97a14.964,14.964,0,0,1,0-28.1,1,1,0,0,0,.6-1v-1.4a.76.76,0,0,0-.6-.8.6.6,0,0,0-.4.1,18,18,0,0,0,0,34.3.608.608,0,0,0,.9-.4c.1-.1.1-.2.1-.4v-1.4A1.332,1.332,0,0,0,260.914,322.97Zm10.6-31.2a.608.608,0,0,0-.9.4c-.1.1-.1.2-.1.4v1.4a1.326,1.326,0,0,0,.6,1,14.964,14.964,0,0,1,0,28.1,1,1,0,0,0-.6,1v1.4a.76.76,0,0,0,.6.8.6.6,0,0,0,.4-.1,18.072,18.072,0,0,0,0-34.4Z"
                  transform="translate(-242.014 -284.67)"
                  fill="#fff"
                />
              </svg>
              <div className="nomCryptoM">
                <span className="NomCompletM">USD Coin</span>
                <span className="nomAbreM">USDC</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">5,50%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Oui</span>
            </Typography>

            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">5 périodes de 1 an</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">300</span>
            </Typography>
            <Typography className="FraisM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">20</span>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary id="panel5a-header">
            <Typography className="BinanceM">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.263"
                height="49.42"
                viewBox="0 0 49.263 49.42"
              >
                <g
                  id="binance-usd-busd-logo"
                  transform="translate(0.003 0.003)"
                >
                  <g
                    id="Layer_1"
                    data-name="Layer 1"
                    transform="translate(0.7 0.71)"
                  >
                    <path
                      id="Path_11117"
                      data-name="Path 11117"
                      d="M78.629.71l5.929,6.071L69.629,21.71,63.7,15.781Z"
                      transform="translate(-54.7 -0.71)"
                      fill="#f0b90b"
                      stroke="#f0b90b"
                      stroke-width="1"
                    />
                    <path
                      id="Path_11118"
                      data-name="Path 11118"
                      d="M87.629,63.71l5.929,6.071L69.629,93.71,63.7,87.781Z"
                      transform="translate(-54.7 -54.71)"
                      fill="#f0b90b"
                      stroke="#f0b90b"
                      stroke-width="1"
                    />
                    <path
                      id="Path_11119"
                      data-name="Path 11119"
                      d="M6.629,126.71l5.929,6.071L6.629,138.71.7,132.781Z"
                      transform="translate(-0.7 -108.71)"
                      fill="#f0b90b"
                      stroke="#f0b90b"
                      stroke-width="1"
                    />
                    <path
                      id="Path_11120"
                      data-name="Path 11120"
                      d="M150.629,126.71l5.929,6.071L132.629,156.71l-5.929-5.929Z"
                      transform="translate(-108.7 -108.71)"
                      fill="#f0b90b"
                      stroke="#f0b90b"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
              <div className="nomCryptoM">
                <span className="NomCompletM">Binance USD</span>
                <span className="nomAbreM">BUSD</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">5,50%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Oui</span>
            </Typography>

            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">5 périodes de 1 an</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">300</span>
            </Typography>
            <Typography className="FraisM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">20</span>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary id="panel6a-header">
            <Typography className="DAIM">
              <svg
                id="multi-collateral-dai-dai-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  id="Path_11134"
                  data-name="Path 11134"
                  d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z"
                  fill="#f5ac37"
                />
                <path
                  id="Path_11135"
                  data-name="Path 11135"
                  d="M97.734,113.672h9.12c.194,0,.286,0,.3-.255a17.432,17.432,0,0,0,0-2.79c0-.18-.09-.255-.285-.255H88.719c-.225,0-.285.075-.285.285v2.67c0,.345,0,.345.36.345Zm8.4-6.42a.292.292,0,0,0,0-.21,5.932,5.932,0,0,0-.542-.945,7.652,7.652,0,0,0-1.11-1.4,3.68,3.68,0,0,0-.69-.675,10.782,10.782,0,0,0-4.5-2.28,11.187,11.187,0,0,0-2.55-.27H88.688c-.225,0-.255.09-.255.285v5.324c0,.225,0,.285.285.285h17.309s.15-.03.18-.12h-.073Zm0,9.54a3.5,3.5,0,0,0-.767,0H88.734c-.225,0-.3,0-.3.3V122.3c0,.24,0,.3.3.3h7.68a3.836,3.836,0,0,0,1.094-.075,11.433,11.433,0,0,0,3.256-.72,5.96,5.96,0,0,0,1.094-.51h.1a9.745,9.745,0,0,0,4.185-4.213s.1-.227-.013-.286ZM85.42,125.3V117c0-.2,0-.225-.24-.225H81.925c-.18,0-.255,0-.255-.24v-2.85h3.48c.194,0,.27,0,.27-.255v-2.82c0-.18,0-.225-.24-.225H81.925c-.18,0-.255,0-.255-.24v-2.64c0-.165,0-.21.24-.21h3.225c.225,0,.285,0,.285-.285V98.93c0-.24,0-.3.3-.3H96.969a17.14,17.14,0,0,1,2.43.27,14.662,14.662,0,0,1,4.695,1.74,13.231,13.231,0,0,1,2.64,2.04,14.454,14.454,0,0,1,1.6,1.995,12.03,12.03,0,0,1,1.172,2.3.391.391,0,0,0,.448.315h2.685c.345,0,.345,0,.36.33v2.46c0,.24-.09.3-.33.3H110.6c-.21,0-.27,0-.255.27a15.322,15.322,0,0,1,0,2.744c0,.255,0,.285.286.285H113c.1.135,0,.27,0,.406a3.03,3.03,0,0,1,0,.524v1.815c0,.255-.075.33-.3.33h-2.835a.375.375,0,0,0-.435.285,12,12,0,0,1-3.15,4.59,18.068,18.068,0,0,1-1.6,1.29c-.6.346-1.185.705-1.8.99a16.218,16.218,0,0,1-3.54,1.124,18.457,18.457,0,0,1-3.51.285H85.415V125.3Z"
                  transform="translate(-72.85 -87.978)"
                  fill="#fefefd"
                />
              </svg>
              <div className="nomCryptoM">
                <span className="NomCompletM">DAI</span>
                <span className="nomAbreM">DAI</span>
              </div>
            </Typography>
            <Typography className="interetM">
              <span className="text_interet">Intérêts annuels</span>
              <span className="cryptoPourcentageM">5,50%</span>
            </Typography>
            <ChevronTabMobile className="chevronTabMobile" />
          </AccordionSummary>
          <AccordionDetails className="exempleM">
            <Typography className="StableM">
              <span className="titreExemple">Stablecoin</span>
              <span className="adeposerM">Oui</span>
            </Typography>

            <Typography className="DuréeM">
              <span className="titreExemple">Durée max du contrat</span>
              <span className="adeposerM">5 périodes de 1 an</span>
            </Typography>
            <Typography className="DepotM">
              <span className="titreExemple">Min à déposer</span>
              <span className="adeposerM">300</span>
            </Typography>
            <Typography className="FraisM">
              <span className="titreExemple">Frais de retrait anticipé</span>
              <span className="adeposerM">20</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="content_tableauM">
        <div className="CryptoBtnM">
          <h7>
            Ne laissez pas vos <br />
            <span className="blueCryptov2">cryptos dormir</span> !
          </h7>
          <Link className="btn-sortM" id="contact" to="#idcontact">
            Contactez-nous !
          </Link>
        </div>
      </div>
    </div>
  );
}
