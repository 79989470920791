import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const SignupSchema = yup.object().shape({
  firstName: yup.string().required("Veuillez saisir votre nom"),
  mail: yup
    .string()
    .required("Veuillez saisir une adresse mail")
    .email("Veuillez saisir une adresse mail valide"),
  phone: yup
    .number()
    .required()
    .typeError("Veuillez saisir un numéro de téléphone"),
  message: yup
    .string()
    .min(20, "Veuillez saisir un message d'au moins 20 caractères")
    .max(700)
    .required("Veuillez saisir un message"),
  object: yup
    .string()
    .required("Veuillez saisir l'objet de votre demande")
    .oneOf(
      [
        "Demande d'ouverture de contrat",
        "Informations complémentaires sur notre activité",
        'Je ne comprends pas le terme "stablecoin”',
        "Comment s'inscrire sur une plateforme d'échange ?",
        "Comment acheter de la crypto-monnaie ?",
        "Autre",
      ],
      "Veuillez séléctionner l'objet de votre demande"
    ),
  checkbox: yup
    .boolean()
    .oneOf(
      [true],
      "Veuillez accepter les conditions générales et la politique de confidentialité du site"
    ),
});

const FormContact = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [civilite, setCivilite] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      checkbox: false,
    },
  });
  const onSubmit = (data) => {
    sendFeedback("template_ixm86xh", {
      nom,
      prenom,
      civilite,
      value,
      email,
      object,
      message,
    });
  };

  const sendFeedback = (templateId, variables) => {
    window.emailjs
      .send("service_fwv375l", templateId, variables)
      .then((res) => {
        toast.success("Message envoyé", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNom("");
        setPrenom("");
        setCivilite("");
        setValue("");
        setEmail("");
        setObject("");
        setMessage("");
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite, veuillez réessayer dans quelque minutes",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  return (
    <div className="formulaire">
      <div className="gradiantForm7"></div>
      <div className="gradiantForm8"></div>
      <div className="container">
        <h2>Nos coordonnées</h2>
        <p>
          Vous pouvez nous contacter via notre formulaire de contact, ou en nous
          appelant aux numéros ci-dessous du lundi au samedi.
        </p>
        <div className="role">
          <div className="info">
            <h3>de 09h à 17h</h3>
            <h4>+33 7 88 80 55 04</h4>
            <h5>Bruno Berchiatti - CEO</h5>
          </div>
        </div>
        <div className="role-2">
          <div className="info">
            <h3>de 17h à 01h</h3>
            <h4>+33 6 65 36 11 90</h4>
            <h5>Jimmy Losserand - Co-Fondateur</h5>
          </div>
        </div>
      </div>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-content">
          <div className="radio">
            <h5>Civilité</h5>

            <input
              type="radio"
              name="Civilite"
              onChange={(e) => setCivilite(e.target.value)}
              value="M."
            />
            <label className="choix" for="M.">
              M.
            </label>

            <input
              type="radio"
              name="Civilite"
              onChange={(e) => setCivilite(e.target.value)}
              value="Mme."
            />
            <label className="choix" for="Mme.">
              Mme.
            </label>

            <input
              type="radio"
              name="Civilite"
              onChange={(e) => setCivilite(e.target.value)}
              value="Autre"
            />
            <label className="choix" for="Autre">
              Autre
            </label>
          </div>

          <div className="nom-container">
            <label className="titreForm">Nom *</label>
            <input
              {...register("firstName")}
              onChange={(e) => setNom(e.target.value)}
              value={nom}
              id="nom"
              type="text"
              placeholder="Votre Nom"
            />
            <div className="boxhelper">
              {errors.firstName && (
                <p className="helperText">{errors.firstName.message}</p>
              )}
            </div>
          </div>

          <div className="prenom-container">
            <label className="labelPrenom">Prénom (Optionnel)</label>
            <input
              {...register("lastName")}
              onChange={(e) => setPrenom(e.target.value)}
              value={prenom}
              id="prenom"
              type="text"
              placeholder="Votre prénom"
            />
            {errors.lastName && (
              <p className="helperText">{errors.lastName.message}</p>
            )}
          </div>

          <div className="email-content">
            <label className="titreForm">Adresse mail *</label>
            <input
              {...register("mail")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              id="email"
              type="text"
              placeholder="Votre adresse mail"
            />
            <div className="boxhelper">
              {errors.mail && (
                <p className="helperText">{errors.mail.message}</p>
              )}
            </div>
          </div>

          <div className="phone-container">
            <label className="labelPhone">Téléphone *</label>
            <PhoneInput
              {...register("phone")}
              onChange={setValue}
              value={value}
              placeholder="Votre téléphone"
              id="phone"
            />
            <div className="boxhelper">
              {errors.phone && (
                <p className="helperText">{errors.phone.message}</p>
              )}
            </div>
          </div>

          <div className="object-container">
            <select
              {...register("object")}
              className="object"
              type="list"
              id="object"
              name="object"
              onChange={(e) => setObject(e.target.value)}
            >
              <option value="Quel est l'objet de votre demande ?">
                Quel est l'objet de votre demande ?
              </option>
              <option value="Demande d'ouverture de contrat">
                Demande d'ouverture de contrat
              </option>
              <option value="Informations complémentaires sur notre activité">
                Informations complémentaires sur notre activité
              </option>
              <option value='Je ne comprends pas le terme "stablecoin”'>
                Je ne comprends pas le terme "stablecoin”
              </option>
              <option value="Comment s'inscrire sur une plateforme d'échange ?">
                Comment s'inscrire sur une plateforme d'échange ?
              </option>
              <option value="Comment acheter de la crypto-monnaie ?">
                Comment acheter de la crypto-monnaie ?
              </option>
              <option value="Autre">Autre</option>
            </select>
            <div className="boxhelper">
              {errors.object && (
                <p className="helperText">{errors.object.message}</p>
              )}
            </div>
          </div>
          <div className="message-container">
            <h5>Message *</h5>
            <textarea
              {...register("message")}
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Votre message"
            />
            <div className="boxhelper">
              {errors.message && (
                <p className="helperText">{errors.message.message}</p>
              )}
            </div>
          </div>
          <div className="checkbox">
            <div className="containercheck">
              <label className="titreCheck">
                J’ai lu et j’accepte les{" "}
                <a href="./Media/Conditions.pdf" target="_blanck">
                  conditions générales{" "}
                </a>{" "}
                et la{" "}
                <a href="./Media/Politique.pdf" target="_blanck">
                  politique de confidentialité
                </a>{" "}
                du site
              </label>
              <input
                {...register("checkbox")}
                type="checkbox"
                id="check"
                name="checkbox"
              />
            </div>
            <div className="boxhelper1">
              {errors.checkbox && (
                <p className="helperText">{errors.checkbox.message}</p>
              )}
            </div>
          </div>
        </div>
        <input type="submit" value="Envoyer" id="mobile" />
      </form>
      <div className="grandiantForm6"></div>
    </div>
  );
};

export default FormContact;
