import React from "react";

function Chevron(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      min-width="10"
      min-height="10"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      className={props.className}
    >
      <path
        d="M4,0V4H0V6H4v4H6V6h4V4H6V0Z"
        transform="translate(0 0)"
        fill="#1f2c4d"
      />
    </svg>
  );
}

export default Chevron;
