import React from "react";
import LogoHeader from "../assets/Logo/full-lockup-horizontal_color.svg";
import LogoTW from "../assets/Logo/LogoTW.svg";
import LogoFB from "../assets/Logo/LogoFB.svg";
import LogoIN from "../assets/Logo/LogoIN.svg";
import LogoLink from "../assets/Logo/LogoLink.svg";
import SideBar from "./Sidebar";

const Header = () => {
  return (
    <div className="header">
      <SideBar pageWrapId={"page-wrap"} outerContainerId={"sidebar"} />
      <a href="/">
        <img src={LogoHeader} className="logo-header" alt="logo" />
      </a>
      <ul className="content">
        <li>
          <a
            href="https://www.facebook.com/Prestaefunds/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoFB} className="logo-FB" alt="logoFacebook" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/prestae_funds/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoIN} className="logo-IN" alt="logoInstagram" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/PrestaeFunds"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoTW} className="logo-TW" alt="logoTwitter" />
          </a>
        </li>
        <li>
          <img src={LogoLink} className="logo-Link" alt="logoLinkdIn" />
        </li>
      </ul>
    </div>
  );
};

export default Header;
