import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import LogoP from "../assets/Logo/logo_menu.svg";

var styles = {
  bmMenu: {
    overflow: "hidden",
  },
  bmCross: {
    width: "5px",
    height: "36px",
    width: "5px",
    height: "40px",
  },
  bmCrossButton: {
    right: "36px",
    top: "30px",
  },
};

export default (props) => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <Menu
      {...props}
      className={"menu-prestae"}
      width={"100%"}
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      styles={styles}
    >
      <div className="logo-menu">
        <img src={LogoP} alt="logo de prestae" className="logo-p-menu" />
      </div>

      <a className="menu-item" href="/" onClick={closeSideBar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.81"
          height="27.038"
          viewBox="0 0 31.81 27.038"
          className="svg-menu"
        >
          <path
            id="Icon_material-home"
            data-name="Icon material-home"
            d="M15.724,31.538V22h6.362v9.543h7.952V18.814H34.81L18.9,4.5,3,18.814H7.771V31.538Z"
            transform="translate(-3 -4.5)"
            fill="#1f2c4d"
          />
        </svg>
        Accueil
      </a>

      <a className="menu-item" href="/#idcontact" onClick={closeSideBar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.81"
          height="23.857"
          viewBox="0 0 31.81 23.857"
          className="svg-menu"
        >
          <path
            id="Icon_metro-contacts-dialer"
            data-name="Icon metro-contacts-dialer"
            d="M31.73,6.427H5.222A2.65,2.65,0,0,0,2.571,9.078V27.633a2.65,2.65,0,0,0,2.651,2.651H31.73a2.639,2.639,0,0,0,2.638-2.651L34.38,9.078A2.65,2.65,0,0,0,31.73,6.427ZM13.174,10.4A3.976,3.976,0,1,1,9.2,14.379,3.979,3.979,0,0,1,13.174,10.4Zm7.952,15.9H5.222V24.982c0-2.651,5.3-4.109,7.952-4.109s7.952,1.458,7.952,4.109Zm5.1-5.3H28.4l2,2.651L27.76,26.3a9.954,9.954,0,0,1-3.983-7.946,9.738,9.738,0,0,1,.371-2.651,9.887,9.887,0,0,1,3.618-5.295L30.4,13.054l-2,2.651H26.229a7.987,7.987,0,0,0-.464,2.651,7.822,7.822,0,0,0,.464,2.651Z"
            transform="translate(-2.571 -6.427)"
            fill="#1f2c4d"
          />
        </svg>
        Contact
      </a>

      <a className="menu-item" href="/#FAQ" onClick={closeSideBar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.81"
          height="31.81"
          viewBox="0 0 31.81 31.81"
          className="svg-menu"
        >
          <path
            id="Icon_material-question-answer"
            data-name="Icon material-question-answer"
            d="M33.219,9.362H30.038V23.676H9.362v3.181a1.6,1.6,0,0,0,1.59,1.59h17.5L34.81,34.81V10.952A1.6,1.6,0,0,0,33.219,9.362ZM26.857,18.9V4.59A1.6,1.6,0,0,0,25.267,3H4.59A1.6,1.6,0,0,0,3,4.59V26.857L9.362,20.5h15.9A1.6,1.6,0,0,0,26.857,18.9Z"
            transform="translate(-3 -3)"
            fill="#1f2c4d"
          />
        </svg>
        F.A.Q
      </a>

      <a className="menu-droit" href="./Media/Mentions_legales.pdf">
        Mentions Légales
      </a>
      <a className="menu-droit-2" href="./Media/Politique.pdf" target="_blank">
        Politique de condidentialité
      </a>

      <div className="icon-reseau">
        <a
          className="facebook-icon"
          href="https://www.facebook.com/Prestaefunds/"
        >
          <svg
            id="Icon_awesome-facebook-square"
            data-name="Icon awesome-facebook-square"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="#1f2c4d"
          >
            <rect
              id="FB"
              width="48"
              height="48"
              rx="5"
              fill="#1f2c4d"
              opacity="0"
            />
            <path
              id="Icon_awesome-facebook-square-2"
              data-name="Icon awesome-facebook-square"
              d="M25,2.25H3a3,3,0,0,0-3,3v22a3,3,0,0,0,3,3h8.578V20.731H7.641V16.25h3.938V12.835c0-3.884,2.312-6.03,5.854-6.03a23.854,23.854,0,0,1,3.47.3V10.92H18.948a2.24,2.24,0,0,0-2.526,2.421V16.25h4.3l-.687,4.481H16.422V30.25H25a3,3,0,0,0,3-3v-22a3,3,0,0,0-3-3Z"
              transform="translate(10 7.75)"
            />
          </svg>
        </a>

        <a
          className="instagram-icon"
          href="https://www.instagram.com/prestae_funds/?hl=fr"
        >
          <svg
            id="Icon_awesome-instagram"
            data-name="Icon awesome-instagram"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="#1f2c4d"
          >
            <rect
              id="TW"
              width="48"
              height="48"
              rx="5"
              fill="#c2c6cf"
              opacity="0"
            />
            <path
              id="Icon_awesome-instagram-2"
              data-name="Icon awesome-instagram"
              d="M14,9.059a7.179,7.179,0,1,0,7.179,7.179A7.167,7.167,0,0,0,14,9.059ZM14,20.9a4.667,4.667,0,1,1,4.667-4.667A4.676,4.676,0,0,1,14,20.9Zm9.147-12.14a1.674,1.674,0,1,1-1.674-1.674A1.671,1.671,0,0,1,23.148,8.765Zm4.755,1.7A8.286,8.286,0,0,0,25.641,4.6a8.341,8.341,0,0,0-5.867-2.262c-2.312-.131-9.241-.131-11.552,0A8.329,8.329,0,0,0,2.355,4.592,8.314,8.314,0,0,0,.093,10.458c-.131,2.312-.131,9.241,0,11.552a8.286,8.286,0,0,0,2.262,5.867,8.351,8.351,0,0,0,5.867,2.262c2.312.131,9.241.131,11.552,0a8.286,8.286,0,0,0,5.867-2.262A8.341,8.341,0,0,0,27.9,22.011c.131-2.312.131-9.234,0-11.546ZM24.916,24.491a4.725,4.725,0,0,1-2.662,2.662c-1.843.731-6.217.562-8.253.562s-6.417.162-8.253-.562a4.725,4.725,0,0,1-2.662-2.662c-.731-1.843-.562-6.217-.562-8.253s-.162-6.417.562-8.253A4.725,4.725,0,0,1,5.747,5.323C7.591,4.592,11.964,4.76,14,4.76s6.417-.162,8.253.562a4.725,4.725,0,0,1,2.662,2.662c.731,1.843.562,6.217.562,8.253S25.647,22.654,24.916,24.491Z"
              transform="translate(10.005 7.762)"
            />
          </svg>
        </a>

        <a className="twitter-icon" href="https://twitter.com/PrestaeFunds">
          <svg
            id="Icon_awesome-twitter"
            data-name="Icon awesome-twitter"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="#1f2c4d"
          >
            <rect
              id="TW"
              width="48"
              height="48"
              rx="5"
              fill="#c2c6cf"
              opacity="0"
            />
            <path
              id="Icon_awesome-twitter-2"
              data-name="Icon awesome-twitter"
              d="M30.931,10.359c.022.306.022.613.022.919,0,9.341-7.109,20.1-20.1,20.1A19.967,19.967,0,0,1,0,28.209a14.617,14.617,0,0,0,1.706.087,14.15,14.15,0,0,0,8.772-3.019,7.078,7.078,0,0,1-6.606-4.9,8.91,8.91,0,0,0,1.334.109,7.473,7.473,0,0,0,1.859-.241A7.067,7.067,0,0,1,1.4,13.312v-.087a7.116,7.116,0,0,0,3.194.9,7.076,7.076,0,0,1-2.187-9.45,20.084,20.084,0,0,0,14.569,7.394,7.976,7.976,0,0,1-.175-1.619A7.072,7.072,0,0,1,29.028,5.612a13.911,13.911,0,0,0,4.484-1.706A7.047,7.047,0,0,1,30.406,7.8a14.164,14.164,0,0,0,4.069-1.094,15.188,15.188,0,0,1-3.544,3.653Z"
              transform="translate(7 6.619)"
            />
          </svg>
        </a>
        <a className="linkdin-icon" href="#">
          {" "}
          <svg
            id="Icon_awesome-linkedin-in"
            data-name="Icon awesome-linkedin-in"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="#1f2c4d"
          >
            <rect
              id="In"
              width="48"
              height="48"
              rx="5"
              fill="#c2c6cf"
              opacity="0"
            />
            <path
              id="Icon_awesome-linkedin-in-2"
              data-name="Icon awesome-linkedin-in"
              d="M6.268,28H.463V9.306h5.8ZM3.362,6.756A3.378,3.378,0,1,1,6.724,3.363,3.39,3.39,0,0,1,3.362,6.756ZM27.994,28H22.2V18.9c0-2.169-.044-4.95-3.018-4.95-3.018,0-3.481,2.356-3.481,4.794V28H9.9V9.306h5.567v2.55h.081a6.1,6.1,0,0,1,5.492-3.019C26.92,8.838,28,12.706,28,17.731V28Z"
              transform="translate(10 9.999)"
            />
          </svg>
        </a>
      </div>
    </Menu>
  );
};
