import React from "react";
import LogoFooter from "../assets/Logo/full-lockup_horizontal_color-inverted.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="containerLink">
        <div className="mention">
          <a href="/">
            <img src={LogoFooter} className="logo-footer" alt="logo" />
          </a>
          <p className="droit">©2022 - Tous droits réservés</p>
          <p className="InfoEntreprise">
            SIREN - 908 942 709 <br /> APE - 66.30Z SARL au capital de 1000€
          </p>
        </div>
        <div className="naviguation">
          <h4>Navigation</h4>
          <ul>
            <li>
              <a href="#FAQ">F.A.Q</a>
            </li>
            <li>
              <a href="#idcontact">Nous contacter</a>
            </li>
            <li>
              <a href="#APropos">À propos de nous</a>
            </li>
          </ul>
        </div>
        <div className="liens">
          <h4 className="generalTitreFooter">Retrouvez-nous ici</h4>
          <h4 className="mobileTitreFooter">Réseaux</h4>
          <ul>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15.903"
                viewBox="0 0 16 15.903"
              >
                <path
                  id="Icon_awesome-facebook"
                  data-name="Icon awesome-facebook"
                  d="M16.563,8.563a8,8,0,1,0-9.25,7.9V10.875H5.28V8.563H7.313V6.8a2.823,2.823,0,0,1,3.022-3.112,12.312,12.312,0,0,1,1.791.156V5.812H11.116a1.156,1.156,0,0,0-1.3,1.249v1.5h2.219l-.355,2.313H9.813v5.591A8,8,0,0,0,16.563,8.563Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#fff"
                />
              </svg>
              <a
                className="footerSocial"
                href="https://www.facebook.com/Prestaefunds/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15.996"
                viewBox="0 0 16 15.996"
              >
                <path
                  id="Icon_awesome-instagram"
                  data-name="Icon awesome-instagram"
                  d="M8,6.135a4.1,4.1,0,1,0,4.1,4.1A4.095,4.095,0,0,0,8,6.135ZM8,12.9a2.666,2.666,0,1,1,2.666-2.666A2.671,2.671,0,0,1,8,12.9Zm5.226-6.935a.957.957,0,1,1-.957-.957A.954.954,0,0,1,13.222,5.967Zm2.716.971a4.734,4.734,0,0,0-1.292-3.352,4.765,4.765,0,0,0-3.352-1.292c-1.321-.075-5.279-.075-6.6,0A4.758,4.758,0,0,0,1.343,3.582,4.75,4.75,0,0,0,.051,6.934c-.075,1.321-.075,5.279,0,6.6a4.734,4.734,0,0,0,1.292,3.352,4.771,4.771,0,0,0,3.352,1.292c1.321.075,5.279.075,6.6,0a4.734,4.734,0,0,0,3.352-1.292,4.765,4.765,0,0,0,1.292-3.352c.075-1.321.075-5.276,0-6.6Zm-1.706,8.013a2.7,2.7,0,0,1-1.521,1.521c-1.053.418-3.552.321-4.715.321s-3.666.093-4.715-.321a2.7,2.7,0,0,1-1.521-1.521c-.418-1.053-.321-3.552-.321-4.715s-.093-3.666.321-4.715A2.7,2.7,0,0,1,3.281,4C4.334,3.582,6.833,3.679,8,3.679s3.666-.093,4.715.321a2.7,2.7,0,0,1,1.521,1.521c.418,1.053.321,3.552.321,4.715S14.65,13.9,14.232,14.951Z"
                  transform="translate(0.005 -2.238)"
                  fill="#fff"
                />
              </svg>
              <a
                className="footerSocial"
                href="https://www.instagram.com/prestae_funds/?hl=fr"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  id="Icon_awesome-linkedin"
                  data-name="Icon awesome-linkedin"
                  d="M14.857,2.25H1.139A1.148,1.148,0,0,0,0,3.4V17.1A1.148,1.148,0,0,0,1.139,18.25H14.857A1.151,1.151,0,0,0,16,17.1V3.4A1.151,1.151,0,0,0,14.857,2.25ZM4.836,15.964H2.464V8.329H4.839v7.636ZM3.65,7.286A1.375,1.375,0,1,1,5.025,5.911,1.376,1.376,0,0,1,3.65,7.286Zm10.075,8.679H11.354V12.25c0-.886-.018-2.025-1.232-2.025-1.236,0-1.425.964-1.425,1.961v3.779H6.325V8.329H8.6V9.371h.032a2.5,2.5,0,0,1,2.246-1.232c2.4,0,2.846,1.582,2.846,3.639Z"
                  transform="translate(0 -2.25)"
                  fill="#fff"
                />
              </svg>
              <a className="footerSocial" href="#" target="_blank">
                LinkedIn
              </a>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  id="Icon_awesome-twitter-square"
                  data-name="Icon awesome-twitter-square"
                  d="M14.286,2.25H1.714A1.715,1.715,0,0,0,0,3.964V16.536A1.715,1.715,0,0,0,1.714,18.25H14.286A1.715,1.715,0,0,0,16,16.536V3.964A1.715,1.715,0,0,0,14.286,2.25ZM12.539,7.921c.007.1.007.2.007.3a6.619,6.619,0,0,1-6.664,6.664,6.634,6.634,0,0,1-3.6-1.05,4.935,4.935,0,0,0,.564.029,4.7,4.7,0,0,0,2.907-1,2.345,2.345,0,0,1-2.189-1.625A2.524,2.524,0,0,0,4.625,11.2,2.343,2.343,0,0,1,2.75,8.9V8.871a2.341,2.341,0,0,0,1.057.3,2.337,2.337,0,0,1-1.043-1.95,2.313,2.313,0,0,1,.318-1.182,6.651,6.651,0,0,0,4.829,2.45,2.347,2.347,0,0,1,4-2.139,4.59,4.59,0,0,0,1.486-.564,2.336,2.336,0,0,1-1.029,1.289,4.661,4.661,0,0,0,1.35-.364A4.93,4.93,0,0,1,12.539,7.921Z"
                  transform="translate(0 -2.25)"
                  fill="#fff"
                />
              </svg>
              <a
                className="footerSocial"
                href="https://twitter.com/PrestaeFunds"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
          </ul>
        </div>
        <div className="liens2">
          <h4>Légal</h4>
          <ul>
            <li>
              <a href="./Media/Mentions_legales.pdf">Mentions légales</a>
            </li>
            <li>
              <a href="./Media/Conditions.pdf " target="_blanck">
                Conditions générales
              </a>
            </li>
            <li>
              <a href="./Media/Politique.pdf" target="_blanck">
                Politique de confidentialité
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
