import React from "react";
import Accordion from "./Data";
import Illu3 from "../assets/illustration/illustration-3_light.svg";
import Illu3Tab from "../assets/illustration/illustration-3_tablet_light.svg";
import { HashLink as Link } from "react-router-hash-link";

import "../styles/components/_accordion.scss";

const Data = () => {
  return (
    <div className="Accordion" id="FAQ">
      <div className="titreFAQ">
        <h6>Des questions ?</h6>
      </div>
      <Accordion
        title="1.1 - Peut-on ouvrir un contrat avec de l'argent fiduciaire (€uros ou $dollars par ex.) ?"
        content="Non. Nous ne sommes pas un établissement financier. C'est pourquoi, nous avons uniquement le droit de recevoir et d'envoyer des crypto-monnaies, car ces dernières ne sont pas considérées comme des actifs monétaires classiques.
        "
        id="Q1"
      />
      <Accordion
        title="1.2 - Quelles crypto-monnaies faut-il utiliser lors de l'ouverture d'un contrat ?"
        content="Nous acceptons toutes les crypto-monnaies suivantes triés en 2 catégories : <br/>
        - Les stablecoin : USDT, USDC, BUSD, DAI <br/>
        - Les cryptos “classiques” : Bitcoin et Ethereum <br/>
        Vous trouverez notre tableau récapitulatif juste <a href='#tableauid'>ici</a>."
        id="Q2"
      />
      <Accordion
        title="1.3 - Qu’est ce qu’un stablecoin ?"
        content="Adieu les monnaies trop volatiles comme Bitcoin. Un stablecoin est une crypto-monnaie dont la valeur ne change pas. Par exemple, l’USD Coin (USDC) est stablecoin indexé sur le dollar américain, ainsi 1 USDC sera toujours égal à $1. Pour plus d’informations pour plus d'informations <a href='https://lehub.bpifrance.fr/les-stablecoins-sont-ils-lavenir-de-la-cryptomonnaie/'>lehub.bpifrance.fr/les-stab...</a>). "
        id="Q2"
      />
      <Accordion
        title="1.4 - Comment acheter des crypto-monnaies ?"
        content="Vous pouvez acheter des crypto-monnaies sur une plateforme d'échange telle que <a href='https://www.binance.com/'>Binance</a> pour n'en citer qu'une."
        id="Q3"
      />
      <Accordion
        title="1.5 - Comment envoyer de la crypto-monnaie ?"
        content="Tous les portefeuilles de crypto-monnaies ont une adresse publique (pouvant être considérée comme un IBAN) afin de recevoir de la crypto-monnaie (le lien ci-après vous renverra sur la FAQ de Binance qui explique comment envoyer et recevoir des crypto sur leur plateforme : <a href='https://www.binance.com/fr/support/faq/85a1c394ac1d489fb0bfac0ef2fceafd'>binance.com/fr/supp...</a>).
        "
        id="Q4"
      />
      <Accordion
        title="2.1 - Quel est le montant minimum pour ouvrir un contrat ?
        "
        content="Vous trouverez notre tableau récapitulatif juste <a href='#tableauid'>ici</a>."
        id="Q5"
      />
      <Accordion
        title="2.2 - Comment être sûr que mes cryptos-monnaies sont en sécurité ?
        "
        content="Notre activité consiste à utiliser l'écosystème de la finance décentralisée (DeFi). Nous garantissons de ne jamais mettre plus de 25% sur une seule et même plateforme ce qui réduit énormément les risques. Aussi, nous utilisons des portefeuilles hardware tel que <a href='https://www.ledger.com/'>Ledger</a>.
        "
        id="Q6"
      />
      <Accordion
        title="2.3 - Quelle est la durée des contrats ?
        "
        content="La durée des contrats est différente pour chaque type d’actifs. 1 an minimum pour les stablecoin jusqu’à un maximum de 5 ans.
        6 mois minimum pour les autres cryptos et 3 ans maximum.
        "
        id="Q7"
      />
      <Accordion
        title="2.4 - Y a-t-il des frais ?
        "
        content="Les seuls frais que nous appliquons sont sur le retrait de votre investissement avant les termes de la première période qui sont équivalents. Il n'y a pas de frais de fonctionnement ou de frais de dossier car notre rémunération se fera sur la différence entre ce que l'on arrive à générer comme rendements et ce que l'on redistribue aux prêteurs.

        "
        id="Q8"
      />
      <Accordion
        title="2.5 - Quels sont les intérêts garantis avec ces contrats ?
        "
        content="Les intérêts annuels sont différents pour chaque actif. Vous trouverez notre tableau récapitulatif juste <a href='#tableauid'>ici</a>.
        "
        id="Q9"
      />
      <Accordion
        title="2.6 - Comment les intérêts sont-ils garantis ?
        "
        content="Nous pouvons garantir ces rendements car nous bloquons sur un portefeuille au moins le montant minimum garanti par tous nos contrats."
        id="Q10"
      />
      <Accordion
        title="2.7 – Y a-t-il d'autres risques ?
        "
        content="Les autres risques potentiels sont principalement liés au dollar mais aussi au stablecoin utilisé (se référer au lien indiqué dans la réponse à la question 'Quelles crypto-monnaies faut-il utiliser lors de l'ouverture d'un contrat ?')."
        id="Q11"
      />
      <Accordion
        title="3.1 - Comment ouvrir un contrat ?
        "
        content="Vous pouvez nous contacter via le <a href='#idcontact'>formulaire ci-dessus</a> avec comme objet 'Demande d'ouverture de contrat'."
        id="Q12"
      />
      <div className="InfoSupp">
        *Si un réseau autre que ERC-20 est utilisé pour le transfert des
        crypto-monnaies. <br />
        **Pour toute nouvelle ouverture de contrat avant le 31 août 2022.
      </div>
      <div className="IlluQuestion">
        <div className="gradiantForm9"></div>
        <img src={Illu3} className="Illu3" alt="IllustrationQuestion" />
        <img src={Illu3Tab} className="IlluTab" alt="IlluTablet" />
        <h2>
          Vous ne trouvez <br />
          pas votre question ?
        </h2>
        <Link className="btn-sort" id="contact" to="#idcontact">
          Contactez-nous !
        </Link>
      </div>
    </div>
  );
};

export default Data;
